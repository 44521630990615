import * as moment from 'moment';
import Decimal from 'decimal.js';

export const compound = (startingValue: Decimal, interestRate: Decimal, periods: number) => {
    if (interestRate.lessThan(1) || periods <= 0) {
        return new Decimal(startingValue);
    }

    return new Decimal(startingValue).mul(Decimal.pow(new Decimal(interestRate), new Decimal(periods)));
};

export const refreshFigures = (userData: any) => {
    if (userData.account_summary) {
        const now = moment.utc();
        const secondsToCompute = now.diff(userData.account_summary.calculation_timestamp, 'seconds');

        let increaseToPortfolioEur = new Decimal(0);
        let increaseToPortfolioGbp = new Decimal(0);

        const newState = {
            ...userData,
            account_summary: {
                ...userData.account_summary,
                calculation_timestamp: now,
                coin_balances: userData.account_summary.coin_balances.map((coinBalance: any) => {
                    const coinBalanceAsDecimalGbp = new Decimal(coinBalance.gbp);
                    const coinBalanceAsDecimalEur = new Decimal(coinBalance.eur);
                    const coinBalanceAsDecimalRaw = new Decimal(coinBalance.value);
                    const coinBalanceAsDecimalCustom = new Decimal(coinBalance.custom);

                    const newAmountGbp = coinBalance.current_yield
                        ? compound(coinBalanceAsDecimalGbp, new Decimal(coinBalance.current_yield), secondsToCompute)
                        : new Decimal(coinBalance.gbp);

                    const newAmountEur = coinBalance.current_yield
                        ? compound(coinBalanceAsDecimalEur, new Decimal(coinBalance.current_yield), secondsToCompute)
                        : new Decimal(coinBalance.eur);

                    const newAmountRaw = coinBalance.current_yield
                        ? compound(coinBalanceAsDecimalRaw, new Decimal(coinBalance.current_yield), secondsToCompute)
                        : new Decimal(coinBalance.value);

                    const newAmountCustom = coinBalance.current_yield
                        ? compound(coinBalanceAsDecimalCustom, new Decimal(coinBalance.current_yield), secondsToCompute)
                        : new Decimal(coinBalance.custom);

                    increaseToPortfolioEur = increaseToPortfolioEur.add(newAmountEur.minus(coinBalanceAsDecimalEur));
                    increaseToPortfolioGbp = increaseToPortfolioGbp.add(newAmountGbp.minus(coinBalanceAsDecimalGbp));

                    return {
                        ...coinBalance,
                        ...(coinBalance.current_yield
                            ? {
                                  gbp: newAmountGbp.toString(),
                                  eur: newAmountEur.toString(),
                                  value: newAmountRaw.toString(),
                                  custom: newAmountCustom.toString(),
                              }
                            : {}),
                    };
                }),
            },
        };

        const increment = (payloadKey: string, value: Decimal) => {
            newState.account_summary[payloadKey] = new Decimal(newState.account_summary[payloadKey])
                .plus(value)
                .toString();
        };

        increment('eur_balance', increaseToPortfolioEur);
        increment('total_earnings_eur', increaseToPortfolioEur);

        increment('gbp_balance', increaseToPortfolioGbp);
        increment('total_earnings_gbp', increaseToPortfolioGbp);
        return newState;
    }

    return userData;
};
