import Decimal from 'decimal.js';

export const convertAmountAsCurrency = (currencyVal: number | string, type = 'crypto') => {
    if (typeof currencyVal === 'number') return currencyVal;

    const currencyAmount = currencyVal.trim();

    if (type === 'crypto') {
        if (currencyVal.includes(',')) {
            const commaToDot = currencyVal.replace(/,/g, '.');
            const replaceAllWithEmptyExceptLast = commaToDot.replace(/[.,](?=.*[.,])/g, '');
            return parseFloat(replaceAllWithEmptyExceptLast);
        }
    }
    if (type === 'fiat') {
        if (currencyAmount.includes(',')) {
            let result = currencyAmount.replace(/[^0-9]/g, '');
            if (/[,.]\d{2}$/.test(currencyAmount)) {
                result = result.replace(/(\d{2})$/, '.$1');
                // return parseFloat(result);
                return Number(parseFloat(result).toFixed(2));
            }
        }
    }
    return currencyAmount;
};

export const evalAmountAsCurrency = (currencyVal: number | string, type = 'crypto'): boolean => {
    let value = currencyVal;
    if (typeof value === 'number') {
        return !(value <= 0);
    }
    if (typeof value === 'string') {
        value = value.trim();
        if (value === '') return false;
        if (Number(value) === 0 || Number.isNaN(value)) return false;

        if (value.includes(',') || value.includes('.')) {
            if (type === 'fiat') {
                if (/[,.]\d{2}$/.test(String(convertAmountAsCurrency(value, 'fiat')))) return true;
            }
            if (type === 'crypto' && convertAmountAsCurrency(value, 'crypto')) return true;
        }

        return !Number.isNaN(Number(value));
    }
    return false;
};

export const evalInput = (input: any, maxAmountAllowed: any, type = 'crypto'): boolean => {
    const isValidCurrency = evalAmountAsCurrency(input, type);
    if (isValidCurrency) {
        return new Decimal(convertAmountAsCurrency(input, type)).greaterThan(new Decimal(maxAmountAllowed));
    }
    return true;
};
