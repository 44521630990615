/* eslint-disable camelcase */
/* eslint-disable lines-between-class-members */

import * as moment from 'moment';

const productMap: { [key: string]: string } = {
    stablecoin: 'USDC',
    auto_eth: 'ETH',
    auto_btc: 'BTC',
    maple: 'USDC',
};

const currencyMap: { [key: string]: string } = {
    'usd-coin': 'USDC',
    ethereum: 'ETH',
    bitcoin: 'BTC',
    dai: 'DAI',
    tether: 'USDT',
    gbp: 'GBP',
    eur: 'EUR',
};

export const buildCsv = (transactions: any) => {
    const headers = 'Type,Currency,Product,Amount From,Amount To,Amount,Exchange Rate,Fee,Info,Currency Pair,Timestamp';

    const hiddenElement = document.createElement('a');
    const href = encodeURI(
        `${headers}\n${transactions
            .map(
                (row: any) =>
                    `${row.type.toUpperCase()},${row.currency_label},${row.product_label || ''},${
                        row.amount_from || 0
                    },${row.amount_to},${row.amount},${row.exchange_rate || ''},${row.fee || 0},${row.type_label},${
                        row.currency_pair
                    },${moment.utc(row.created_at).format('DD-MM-YYYY HH:mm:ss')}`
            )
            .join('\n')}`
    );

    hiddenElement.href = `data:text/csv;charset=utf-8,${href}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = 'AqruTransactions.csv';

    return hiddenElement.click();
};

export const buildKoinlyCsv = (transactions: any) => {
    const headers = `Date, Sent Amount, Sent Currency, Received Amount, Received Currency, Fee Amount, Fee Currency, Label`;
    const koinlyLabel = 'Loan Interest';

    const hiddenElement = document.createElement('a');

    const href = encodeURI(
        `${headers}\n${transactions
            // no need to factor in like for like transactions since they dont incur a fee and dont really change hands
            .filter((row: any) => !['BTC/BTC', 'ETH/ETH', 'USDC/USDC'].includes(row.currency_pair))
            .map((row: any) => {
                const timestamp = moment.utc(row.created_at).format('DD-MM-YYYY HH:mm:ss');

                const currency = currencyMap[row.currency];
                const product = productMap[row.product];

                if (row.type === 'deposit' || row.type === 'Interest Deposit') {
                    if (row.type_label === 'Deposit Interest') {
                        return `${timestamp},,,${row.amount},${row.currency},,,${koinlyLabel}`;
                    }
                    return `${timestamp},,,${row.amount},${currency},,`;
                }

                if (row.type === 'withdrawal') {
                    return `${timestamp},-${row.amount},${currency},,,${row.fee},,`;
                }

                let amountFrom = row.amount_from || 0;
                let amountTo = row.amount_to || 0;

                if (
                    row.type_label === 'Free Credit' &&
                    ['GBP/USDC', 'EUR/USDC', 'USDT/USDC'].includes(row.currency_pair)
                ) {
                    amountFrom = row.amount;
                    amountTo = row.amount;
                }
                if (row.type === 'exchange') {
                    return `${timestamp},${amountFrom},${currencyMap[row.currency_from]},${amountTo},${
                        currencyMap[row.currency_to]
                    },${row.fee},${currencyMap[row.currency_to]},,`;
                }

                if (row.trade_type === 'BUY') {
                    // free cash money doesn't have quote value
                    if (row.type_label === 'Free Credit' && ['GBP/USDC', 'EUR/USDC'].includes(row.currency_pair)) {
                        amountFrom = 10;
                    }

                    return `${timestamp},${amountFrom},${currency},${amountTo},${product},${row.fee},${product},`;
                }

                return `${timestamp},${amountFrom},${product},${amountTo},${currency},${row.fee},${currency},`;
            })
            .join('\n')}`
    );

    hiddenElement.href = `data:text/csv;charset=utf-8,${href}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = 'KoinlyAqruTransactions.csv';

    return hiddenElement.click();
};
